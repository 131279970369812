import React from 'react';

import JpgDiabeticFoot from '../../../../assets/img/jpg/diabetic-foot.jpg';
import DiabeticFootSC from './diabetic-foot.styled';

const DiabeticFoot = () => {
  return (
    <DiabeticFootSC>
      <div className="left">
        <img src={JpgDiabeticFoot} alt="diabetic-foot" />
      </div>
      <div className="right">
        <p>
          However, we have a solution: Physical vascular therapy physical vascular therapy can
          prevent these complications and, in the case of a developed chronic complication of
          diabetes, effectively help in coping with a diabetic condition.
        </p>
        <p>
          The beneficial effect of Physical vascular therapy on the human body is backed up by a
          number of independent medical studies, as confirmed by the expanding circle of
          enthusiastic over one million users.
        </p>
      </div>
    </DiabeticFootSC>
  );
};

export default DiabeticFoot;
