import React from 'react';

import AboutBiomicrorealSC from './about-biomicroreal.styled';
import JpgHands from '../../../../assets/img/jpg/hands.jpg';

const AboutBiomicroreal = () => {
  return (
    <AboutBiomicrorealSC>
      <div className="left">
        <h3>Why does Everybody Need Physical Vascular Therapy?</h3>
        <p>
          Most civilization diseases (hypertension, diabetes, arteriosclerosis, heart attack,
          stroke, obesity, autoimmune disease, etc.) are caused by
        </p>
        <ul>
          <li>improper lifestyle</li>
          <li>chronic stress</li>
          <li>addictive substances</li>
          <li>(smoking, alcohol)</li>
        </ul>
        <p>These lead to damage of microcirculation and chronic inflammation.</p>
        <p>
          Physical Vascular Therapy eliminates damage of microcirculation and chronic inflammation
          efficiently and effectively, physically, without side effects.
        </p>
      </div>
      <div className="right">
        <img src={JpgHands} alt="diabetes-schema" />
      </div>
    </AboutBiomicrorealSC>
  );
};

export default AboutBiomicroreal;
