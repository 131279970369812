import React from 'react';
import PropTypes from 'prop-types';

import SecondBlockSC from './second-block.styled';

const texts = {
  Diabetes: {
    headingTwo: 'Diabetes - the modern epidemy',
    description:
      'Chronic complications of diabetes seriously affecting the life of every person with diabetes.',
  },
  About: {
    headingTwo: 'Physical Vascular Therapy by Ladislav Pasztor MD,MSc, Biomicroreal terapia Ltd.',
    description: '',
  },
  Migraine: {
    headingTwo: 'Welcome to a World without Migraine and Headache!',
    description:
      '2 x 8 minutes daily and migraine attacks will not recur or will be mild. Simple and effective treatment at home or in the workplace. No drugs. No side effects.',
  },
};

const SecondBlock = ({ type }) => {

  if (type === 'None') {
    return null;
  }

  return (
    <SecondBlockSC>
      <h2>{texts[type].headingTwo}</h2>
      <p className="description">{texts[type].description ? texts[type].description : null}</p>
    </SecondBlockSC>
  );
};

SecondBlock.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SecondBlock;
