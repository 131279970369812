import { css } from 'styled-components';
import { COLOR_PRIMARY_WHITE_10, COLOR_PRIMARY_BLACK_10, BP_MEDIUM } from '../../constants';

// TODO OUTLINE??

export default css`
  body {
    font-family: 'PT Sans', sans-serif;

    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 100%;
  }

  h1 {
    font-size: 76px;
    font-weight: 700;
    line-height: 1.18;
    text-align: center;
    color: ${COLOR_PRIMARY_WHITE_10};
  }

  h2 {
    font-size: 47px;
    font-weight: 700;
    line-height: 1.49;
    text-align: center;
    color: ${COLOR_PRIMARY_BLACK_10};
    margin-bottom: 0;
  }

  h3 {
    font-size: 29px;
    font-weight: 700;
    line-height: 1.38;
    color: ${COLOR_PRIMARY_BLACK_10};
    margin-bottom: 30px;
  }

  p {
    font-size: 20px;
    line-height: 1.5;
    color: ${COLOR_PRIMARY_BLACK_10};
    margin-bottom: 20px;
  }

  @media (max-width: ${BP_MEDIUM}) {
    h1 {
      font-size: 60px;
      font-weight: bold;
      line-height: 1.17;
      color: ${COLOR_PRIMARY_WHITE_10};
      text-align: left;
      padding-left: 25px;
    }

    h2 {
      font-size: 37px;
      font-weight: bold;
      line-height: 1.46;
      color: ${COLOR_PRIMARY_BLACK_10};
      text-align: left;
    }
  }
`;
