import styled from 'styled-components';
import { BP_MEDIUM, COLOR_PRIMARY_BLACK_01 } from '../../constants';

export default styled.div`
  height: 129px;
  border-top: 1px solid ${COLOR_PRIMARY_BLACK_01};

  display: flex;
  justify-content: center;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: space-between;

    &__right {
      font-size: 16px;
      line-height: 1.88;
    }
  }

  @media (max-width: ${BP_MEDIUM}) {
    .row {
      text-align: center;
      flex-direction: column;
      
      &__right {
        margin-top: 30px;
      }
    }
  }
`;
