import styled from 'styled-components';
import { BP_MEDIUM } from '../../constants';

export default styled.div`
  margin-top: 100px;

  display: flex;
  justify-content: space-between;

  .left {
    max-width: 506px;
    width: 100%;

    picture,
    img {
      max-width: 506px;
      width: 100%;
      height: auto;
    }
  }

  .right {
    max-width: 630px;
    padding-left: 75px;
    width: 100%;
    height: auto;

    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    span {
      display: block;
      &.name {
        font-size: 18px;
        line-height: 1.67;
        font-weight: 700;
      }
      &.desc {
        font-size: 14px;
        line-height: 1.43;
      }
    }
  }

  @media (max-width: ${BP_MEDIUM}) {
    margin-top: 50px;
    flex-direction: column;

    .right {
      margin-top: 30px;
      padding: 0;
    }
  }
`;
