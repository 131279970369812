// Local Storage
export const LS_CURRENT_LANGUAGE = 'current_language';

// Routes
export const ROUTE_HOMEPAGE = '/';
export const ROUTE_ABOUT = '/about';
export const ROUTE_MIGRAINE = '/migraine';

// Colors
export const COLOR_PRIMARY_WHITE_10 = 'rgba(255, 255, 255, 1)';
export const COLOR_PRIMARY_BLACK_10 = 'rgba(29,29,29,1)';
export const COLOR_PRIMARY_BLACK_01 = 'rgba(29,29,29,0.1)';
export const COLOR_PRIMARY_ORANGE_10 = 'rgba(255, 126, 41, 1)';
export const COLOR_PRIMARY_GREY_10 = 'rgba(155,155,155, 1)';
export const COLOR_BACKGROUND_ORANGE_10 = 'rgba(255, 244, 237, 1)';

// Breakpoints

// Extra small devices (portrait phones, less than 576px)
export const BP_EXTRA_SMALL = '575.98px';

// Small devices (landscape phones, less than 768px)
export const BP_SMALL = '767.98px';

// Medium devices (tablets, less than 992px)
export const BP_MEDIUM = '991.98px';

// Large devices (desktops, less than 1200px)
export const BP_LARGE = '1199.98px';
