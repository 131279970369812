import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';

import styled from 'styled-components';
import {
  COLOR_PRIMARY_BLACK_10,
  COLOR_PRIMARY_ORANGE_10,
  COLOR_PRIMARY_WHITE_10,
  ROUTE_ABOUT,
  ROUTE_HOMEPAGE,
  ROUTE_MIGRAINE,
} from '../../constants';
import { StateContext } from '../../context';

import SvgBiomicrorealLogo from '../../assets/img/svg/biomicroreal-logo-dark.svg';
import SvgMenuClose from '../../assets/img/svg/mobile-close.svg';

const ResponsiveMenuSC = styled.div`
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: ${COLOR_PRIMARY_WHITE_10};
  padding-top: 50px;
  padding-bottom: 50px;

  .top-menu {
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;

    button {
      background-color: transparent;
      border: 0;
    }
  }

  .menu-items {
    margin-top: 100px;
    text-align: right;

    .menu-item {
      &.is-active {
        border-right: 5px solid ${COLOR_PRIMARY_ORANGE_10};
      }

      padding-right: 30px;

      a {
        font-size: 47px;
        font-weight: bold;
        line-height: 1.49;

        color: ${COLOR_PRIMARY_BLACK_10};

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`;

const ResponsiveMenu = (props) => {
  const {
    location: { pathname }
  } = props;
  const { setResponsiveMenuOpen } = useContext(StateContext);

  return (
    <ResponsiveMenuSC>
      <div className="top-menu">
        <Link to={ROUTE_HOMEPAGE}>
          <img src={SvgBiomicrorealLogo} alt="menu-logo" />
        </Link>
        <button onClick={() => setResponsiveMenuOpen(false)}>
          <img src={SvgMenuClose} alt="menu-close" />
        </button>
      </div>
      <div className="menu-items">
        <div className={`menu-item ${pathname === ROUTE_HOMEPAGE ? 'is-active' : ''}`}>
          <Link to={ROUTE_HOMEPAGE}>Diabetes</Link>
        </div>
        <div className={`menu-item ${pathname === ROUTE_MIGRAINE ? 'is-active' : ''}`}>
          <Link to={ROUTE_MIGRAINE}>Migraine</Link>
        </div>
        <div className={`menu-item ${pathname === ROUTE_ABOUT ? 'is-active' : ''}`}>
          <Link to={ROUTE_ABOUT}>About Us</Link>
        </div>
      </div>
    </ResponsiveMenuSC>
  );
};

export default withRouter(ResponsiveMenu);
