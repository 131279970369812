import React from 'react';

import VascularTherapySC from './vascular-therapy.styled';

const VascularTherapy = () => {
  return (
    <VascularTherapySC>
      <h3>Physical Vascular Therapy is more oxygen for cells of the whole body.</h3>
      <div className="content">
        <div className="content__left">
          <p>
            More oxygen means producing more energy. More nutrients, vitamins and hormones in the
            cells of the whole body, as well as regular removal of cell metabolism waste,
            significantly improve the functioning of cells, organs and the whole body. That's
            Physical Vascular Therapy.
          </p>
        </div>
        <div className="content__right">
          <p>
            Therefore, by applying Physical Vascular Therapy, we can improve a variety of symptoms
            and diseases (spine and joints disorders, back pain, headaches and migraines, chronic
            diabetes complications, sleep disorders, etc.) and also prevent civilization diseases.
          </p>
        </div>
      </div>
    </VascularTherapySC>
  );
};

export default VascularTherapy;
