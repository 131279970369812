import React from 'react';

import VideoBlockSC from './video-block.styled';

import SvgPlayBtn from '../../../../assets/img/svg/play.svg';
import PngJeromeRident from '../../../../assets/img/png/jerome-rident.png';
import PngJeromeRident2X from '../../../../assets/img/png/jerome-rident@2x.png';

const VideoBlock = () => {
  return (
    <VideoBlockSC pixelRatio={window.devicePixelRatio}>
      <div className="play-column">
        <img
          onClick={() => window.open(process.env.REACT_APP_VIDEO_LINK, '_blank')}
          src={SvgPlayBtn}
          alt="play-btn"
        />
      </div>
      <div className="bottom">
        <img src={PngJeromeRident} alt="jerome-rident" />
        <div className="description">
          <span className="name">Jerome Rident, M.D.</span>
          <span className="title">migraine - Bemer therapy</span>
        </div>
      </div>
    </VideoBlockSC>
  );
};

export default VideoBlock;
