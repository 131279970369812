import styled from 'styled-components';
import { BP_MEDIUM, COLOR_PRIMARY_GREY_10 } from '../../constants';

export default styled.div`
  
  max-width: 750px;
  margin: 100px auto 0 auto;
  

  p.description {
    font-size: 24px;
   
    line-height: 1.46;
    text-align: center;
    color: ${COLOR_PRIMARY_GREY_10};
    margin-top: 17px;
    margin-bottom: 0;
  }
  
    @media (max-width: ${BP_MEDIUM}) {
      margin-top: 50px;
      text-align: left;
      padding-left: 25px;
      padding-right: 25px;
      
      p.description {
        text-align: left;
      }
    }
`;
