import React from 'react';
import { HashRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { createBrowserHistory } from 'history';
import BSReboot from './assets/styles/bootstrap-reboot';
import Globals from './assets/styles/globals';
import Layout from './components/layout';
import StateProvider from './context/context'

const history = createBrowserHistory();
const GlobalStyle = createGlobalStyle`
    ${BSReboot}
    ${Globals}
  `;

const App = () => {

  return (
      <HashRouter history={history}>
        <GlobalStyle />
        <StateProvider>
          <Layout />
        </StateProvider>
      </HashRouter>
  );
};

export default App;
