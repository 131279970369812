import React from 'react';

import AboutSC from './about.styled';
import AboutBiomicroreal from './components/about-biomicroreal';
import ContentWrapper from '../../components/content-wrapper';
import VascularTherapy from './components/vascular-therapy';
import BigQuote from '../../components/big-quote';
import PasztorBlock from '../../components/pasztor-block';
import ContactWebinar from '../../components/contact-webinar';
import ApplyTherapy from './components/apply-therapy';

const About = () => {
  return (
    <AboutSC>
      <ContentWrapper>
        <AboutBiomicroreal />
        <VascularTherapy />
        <BigQuote type="about" />
        <PasztorBlock />
        <ApplyTherapy />
        <ContactWebinar />
      </ContentWrapper>
    </AboutSC>
  );
};

export default About;
