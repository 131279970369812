import React from 'react';
import PropTypes from 'prop-types';
import HeaderSC from './header.styled';
import TopMenu from '../top-menu';

const Header = ({ type }) => {
  return (
    <HeaderSC pixelRatio={window.devicePixelRatio} type={type}>
      <TopMenu />
      <div className="header__content">
        <h1>{type !== 'None' ? type : '' }</h1>
      </div>
    </HeaderSC>
  );
};

Header.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Header;
