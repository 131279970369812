import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import messages_sk from '../../translations/sk.json';
import messages_en from '../../translations/en.json';

import { StateContext } from '../../context/context';
import { ROUTE_HOMEPAGE, ROUTE_ABOUT, ROUTE_MIGRAINE, BP_SMALL } from '../../constants';
import Header from '../header';
import Footer from '../footer';
import HomePage from '../../pages/home';
import MigrainePage from '../../pages/migraine';
import AboutPage from '../../pages/about';
import SecondBlock from '../second-block';
import NotFound from './not-found';
import ResponsiveMenu from '../responsive-menu/responsive-menu';

const messages = {
  sk: messages_sk,
  en: messages_en,
};

const resolveHeadingTypeFromUriPath = (path) => {
  if (path === ROUTE_ABOUT) {
    return 'About';
  }
  if (path === ROUTE_MIGRAINE) {
    return 'Migraine';
  }
  if (path === ROUTE_HOMEPAGE) {
    return 'Diabetes';
  }

  return 'None';
};

const updateWindowDimensions = (responsiveView, setResponsiveView) => {
  const floatMaxSmall = parseFloat(BP_SMALL.slice(0, -2));

  const isResponsiveView = window.innerWidth <= floatMaxSmall;

  if (responsiveView !== isResponsiveView) {
    setResponsiveView(isResponsiveView);
  }
};

const Layout = (props) => {
  const {
    location: { pathname },
  } = props;
  const {
    currentLang,
    responsiveView,
    setResponsiveView,
    responsiveMenuOpen,
    setResponsiveMenuOpen,
  } = useContext(StateContext);

  const location = useLocation();

  useEffect(() => {
    const domainRegex = /:\/\/([^\/]+)/;
    const [_, url] = domainRegex.exec(window.location.href);
    const splitted = url.split('.');
    const [ sub, domain, tld ] = splitted;
    //console.log('window.location.href', window.location.href, _ , sub, domain, tld);
    if (sub === 'migraine') {
      window.location.href = `https://diabetes.${domain}.${tld}/#/migraine`;
    }
    if (sub === 'about') {
      window.location.href = `https://diabetes.${domain}.${tld}/#/about`;
    }
  });

  useEffect(() => {
    window.addEventListener('resize', () =>
      updateWindowDimensions(responsiveView, setResponsiveView)
    );
    return () =>
      window.removeEventListener('resize', () =>
        updateWindowDimensions(responsiveView, setResponsiveView)
      );
  });

  useEffect(() => {
    setResponsiveMenuOpen(false);
  }, [location.pathname]);

  if (responsiveMenuOpen) {
    return <ResponsiveMenu />;
  }

  return (
    <IntlProvider locale={currentLang} messages={messages[currentLang]}>
      <Header type={resolveHeadingTypeFromUriPath(pathname)} />
      <SecondBlock type={resolveHeadingTypeFromUriPath(pathname)} />
      <Switch>
        <Route exact path={ROUTE_HOMEPAGE}>
          <HomePage />
        </Route>
        <Route path={ROUTE_ABOUT}>
          <AboutPage />
        </Route>
        <Route path={ROUTE_MIGRAINE}>
          <MigrainePage />
        </Route>
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </IntlProvider>
  );
};

export default withRouter(Layout);
