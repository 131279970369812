import styled from 'styled-components';
import { BP_MEDIUM, COLOR_PRIMARY_BLACK_10, COLOR_PRIMARY_ORANGE_10 } from '../../../../constants';
import SvgDot from '../../../../assets/img/svg/dott.svg';

export default styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;
  .left {
    max-width: 630px;
    padding-right: 75px;
    width: 100%;
    height: auto;

    text-align: left;
  }

  img {
    max-width: 506px;
    width: 100%;
    height: auto;
  }

  ul {
    font-size: 20px;
    line-height: 1.5;
    color: ${COLOR_PRIMARY_BLACK_10};
    list-style-image: url(${SvgDot});
    padding-left: 18px;
  }

  @media (max-width: ${BP_MEDIUM}) {
    flex-direction: column-reverse;

    .left {
      margin-top: 30px;
      padding-right: 0;
    }
  }
`;
