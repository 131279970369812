import React from 'react';

import PasztorBlockSC from './pazstor-block.styled';
import JpgPasztor from '../../assets/img/jpg/ladislav-pasztor.jpg';
import JpgPasztor2x from '../../assets/img/jpg/ladislav-pasztor.jpg@2x.jpg';

const PasztorBlock = () => {
  return (
    <PasztorBlockSC>
      <div className="left">
        <picture>
          {/* We are gonna load big picture if not mobile device */}
          <source media="(min-width: 576px)" srcSet={`${JpgPasztor} 2x, ${JpgPasztor2x} 1x`} />
          {/* Le fallback */}
          <img src={JpgPasztor} alt="Some girl" />
        </picture>
      </div>
      <div className="right">
        <div className="right__top-content">
          <p>
            We have a team of experienced specialists who have many years of experience with
            Physical Vascular Therapy all over the world. They are well prepared to answer your
            questions about the Physical Vascular Therapy, (how, why, etc.).
          </p>
          <p>
            We will keep you up to date on news, new experiences, medical congress results, etc.
            Physical Vascular Therapy will serve you and your whole family in continuous improvement
            and maintenance.
          </p>
        </div>
        <div className="right__bottom-content">
          <span className="name">Ladislav Pasztor,MD,MSc.</span>
          <span className="desc">MSC, President ASL SR</span>
        </div>
      </div>
    </PasztorBlockSC>
  );
};

export default PasztorBlock;
