import React from 'react';

import MigraineAttackSC from './migraine-attack.styled';
import JpgMigraineWoman from '../../../../assets/img/jpg/migraine-woman.jpg';
import JpgMigraineWoman2x from '../../../../assets/img/jpg/migraine-woman@2x.jpg';

const MigraineAttack = () => {
  return (
    <MigraineAttackSC>
      <div className="left">
        <h3>Migraine attack...</h3>
        <p>
          You feel it‘s coming again. And you can't do anything about it. You'll have to lie in the
          dark room again, in total silence. The head hurts and beats, you feel for vomiting.
          Nothing works. Not even the strongest drugs. Do not even go to work. And when it goes, you
          know it will be repeated for some time...
        </p>
        <div className="quote">
          <span className="quote__text">
            Approximately 12% of the population suffers from migraine
          </span>
        </div>
      </div>
      <div className="right">
        <picture>
          {/* We are gonna load big picture if not mobile device */}
          <source media="(min-width: 576px)" srcSet={`${JpgMigraineWoman} 2x, ${JpgMigraineWoman2x} 1x`} />
          {/* Le fallback */}
          <img src={JpgMigraineWoman} alt="Some girl" />
        </picture>
      </div>
    </MigraineAttackSC>
  );
};

export default MigraineAttack;
