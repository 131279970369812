import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { BP_MEDIUM } from '../../constants';

const TestimonialsSC = styled.div`
  margin-top: 100px;

  display: flex;
  justify-content: space-between;
  text-align: left;
  .left {
    max-width: 555px;
    width: 100%;
    height: auto;
  }

  .right {
    max-width: 585px;
    margin-left: 30px;
    width: 100%;
    height: auto;
  }

  @media (max-width: ${BP_MEDIUM}) {
    margin-top: 50px;
    
    flex-direction: column;
    
    .right {
      margin-left: 0;
    }
  }
`;

const Testimonials = ({ type }) => {
  return (
    <TestimonialsSC>
      <div className="left">
        <h3>{type === 'diabetes' ? 'Ľudovít S.' : "Hi, I'm Magdalena M."}</h3>
        {type === 'diabetes' ? (
          <p>
            I have type II diabetes. In December 2018, I discovered a bruise on my right foot that
            ached and grew. I thought I was hit and that's why I didn't go to the doctor. Then it
            turned into an open wound. My General Practitioner told me it was a problem, and I got
            it from diabetes. The surgeon told me it is late for treatment, and he proposed an
            amputation of his thumb. I was frightened, because from the telling of other diabetics,
            I knew that then there would be more inches, and then the whole leg. Therefore, I
            refused the amputation of my thumb. My friend recommended me Bemer at the time, she has
            Bemer device at home, and she is very pleased with Bemer treatment. I don't believe in
            these technics, but I told myself why I wouldn't try it. Dr.Pásztor set me up for
            treatment, I went twice a day. Of course, I used to go to a surgeon for a rebirth. After
            about 14 days of bruising, the wound began to heal. After a month, the wound healed
            completely, and my leg was warm. What is important is that they completely ceased pain
            and tingling in their legs.
          </p>
        ) : null}
        {type === 'migraine' ? (
          <>
            <p>
              I've had severe migraine headaches for years. Every month I was taken out of my normal
              life for several days. I had to lie in a dark room, in absolute silence. I was
              vomiting, and I vomited repeatedly. Nothing helped me, not even the strongest drugs.
              Last April, I read for the first time about physical vascular therapy.
            </p>
            <p>
              After a month of using of the physical vascular therapy I had no headaches for 3
              months. I met a new world without migraine without headaches. But after 3 months I got
              a migraine attack again. So I understand that have to practice the physical vascular
              therapy at home and to practice the therapy twice a day. And my world will be without
              migraine.
            </p>
            <p>
              And my husband, after regular physical vascular treatment, stopped having back pain.
            </p>
          </>
        ) : null}
      </div>
      <div className="right">
        <h3>{type === 'diabetes' ? 'Alžbeta C.' : "Hi, I'm Eva K."}</h3>
        {type === 'diabetes' ? (
          <>
            <p>
              I have a type I. diabetes. I lost my left leg from the knee down about two years ago.
              I was in a lot of pain and little by little my toes were feeling numb. So at first,
              they cut my toes and then, unfortunately, my leg from the knee down. But my problems
              didn’t end. My right leg started to hurt too; it was cold and I discovered some
              wounds. The blood circulation in my right leg got worse and my surgeon recommended me
              to amputate it from the knee down as well. I reached out to MUDr. Pásztor and he
              managed to let me try the Physical Vascular Therapy and explained to me how to
              practice it. I practiced the therapy for three months, twice a day with my daughter’s
              help. My leg started to feel alive again, it was warm and stopped hurting. The wound
              healed after a few days. I realized that I need to practice the therapy at home if I
              want to improve my health as a diabetic patient and It’s very important to practice it
              twice a day, every day.
            </p>
            <p>
              What’s most important for me, is that I still have my leg without wounds and pain.
            </p>
          </>
        ) : null}
        {type === 'migraine' ? (
          <p>
            I've had migraine headaches for years. They are very intense pains and begin in the
            cervical spine. When I see strange lights in front of my eyes, I know another migraine
            attack is coming. Because of the medications prescribed to me by a neurologist, I waved
            less often. But after the physical vascular therapy I have no pain at all, thank you
            Dr.Pasztor.
          </p>
        ) : null}
      </div>
    </TestimonialsSC>
  );
};

Testimonials.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Testimonials;
