import styled from 'styled-components';

import JpgVideoBackground from '../../../../assets/img/jpg/video-thumbnail.jpg';
import JpgVideoBackground2x from '../../../../assets/img/jpg/video-thumbnail@2x.jpg';
import { BP_MEDIUM, COLOR_PRIMARY_BLACK_10 } from '../../../../constants';

export default styled.div`
  margin-top: 100px;
  max-width: 1140px;
  width: 100%;
  height: 600px;

  ${(props) => {
    if (props.pixelRatio === 2) {
      return `background-image: url(${JpgVideoBackground2x})`;
    }
    return `background-image: url(${JpgVideoBackground})`;
  }};

  background-size: cover;
  background-position: center;

  position: relative;

  .play-column {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    img {
      width: 110px;
      height: 110px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }
  }

  .bottom {
    position: absolute;
    bottom: 40px;
    left: 40px;
    height: 50px;
    display: flex;

    .description {
      margin-left: 15px;
      height: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .name {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.67;
        color: ${COLOR_PRIMARY_BLACK_10};
      }

      .title {
        font-size: 14px;
        line-height: 1.43;
        color: ${COLOR_PRIMARY_BLACK_10};
      }
    }
  }

  @media (max-width: ${BP_MEDIUM}) {
    margin-top: 50px;
    height: 172px;
    background-size: cover;
    background-position: center;

    .play-column {
      img {
        width: 70px;
        height: 70px;
      }
    }

    .bottom {
      bottom: 10px;
      left: 10px;
      height: 25px;
       
      img {
        width: 25px;
      } 
       
      .description {
        height: 25px;
        .name {
          font-size: 14px;
        }
        .title {
          font-size: 14px;
        }
      }
    }
  }
`;
