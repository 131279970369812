import React from 'react';

import MigraineSC from './migraine.styled';
import ContentWrapper from '../../components/content-wrapper';
import VideoBlock from './components/video-block';
import PasztorBlock from '../../components/pasztor-block';
import Testimonials from '../../components/testimonials';
import ContactWebinar from '../../components/contact-webinar';
import MigraineAttack from './components/migraine-attack';

const Migraine = () => {
  return (
    <MigraineSC>
      <ContentWrapper>
        <MigraineAttack />
        <VideoBlock />
        <PasztorBlock />
        <Testimonials type="migraine" />
        <ContactWebinar />
      </ContentWrapper>
    </MigraineSC>
  );
};

export default Migraine;
