import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import SvgQuotes from '../../assets/img/svg/quotes.svg';
import { BP_MEDIUM, COLOR_PRIMARY_ORANGE_10 } from '../../constants';

const BigQuoteSC = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-family: 'PT Serif', serif;
    font-size: 50px;
    font-style: italic;
    line-height: 1.4;
    text-align: center;
    color: ${COLOR_PRIMARY_ORANGE_10};
  }

  background-image: url(${SvgQuotes});
  background-position: top left;
  background-repeat: no-repeat;
  height: 210px;

  @media (max-width: ${BP_MEDIUM}) {
    p {
      font-size: 29px;
      font-style: italic;
      line-height: 1.72;
      text-align: center;
    }
  }
`;

const renderText = (type) => {
  if (type === 'diabetes') {
    return (
      <p>
        Try effective, medical studies, <br /> based treatment that regulates microcirculation even
        <br /> in damaged tissues
      </p>
    );
  }
  if (type === 'about') {
    return (
      <p>
        We feel better, and we have better performance and
        <br /> better immunity.
      </p>
    );
  }
};

const BigQuote = ({ type }) => {
  return <BigQuoteSC>{renderText(type)}</BigQuoteSC>;
};

BigQuote.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BigQuote;
