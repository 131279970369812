import styled from 'styled-components';

import JpgHeadingDiabetes from '../../assets/img/jpg/header-images/heading-diabetes.jpg';
import JpgHeadingAbout from '../../assets/img/jpg/header-images/heading-about.jpg';
import JpgHeadingAbout2x from '../../assets/img/jpg/header-images/heading-about@2x.jpg';
import JpgHeadingMigraine from '../../assets/img/jpg/header-images/heading-migraine.jpg';
import JpgHeadingMigraine2x from '../../assets/img/jpg/header-images/heading-migraine@2x.jpg';
import { BP_MEDIUM } from '../../constants';

export default styled.header`
  height: 400px;

  background-position: center;
  background-size: cover;

  ${(props) => {
    if (props.pixelRatio === 1 && props.type === 'About') {
      return `background-image: url(${JpgHeadingAbout})`;
    }
    if (props.pixelRatio === 2 && props.type === 'About') {
      return `background-image: url(${JpgHeadingAbout2x})`;
    }
    if (props.pixelRatio === 1 && props.type === 'Migraine') {
      return `background-image: url(${JpgHeadingMigraine})`;
    }
    if (props.pixelRatio === 2 && props.type === 'Migraine') {
      return `background-image: url(${JpgHeadingMigraine2x})`;
    }
    return `background-image: url(${JpgHeadingDiabetes})`;
  }};

  .header__content {
    height: calc(100% - 140px);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: ${BP_MEDIUM}) {
    height: 330px;
    .header__content {
      padding-top: 200px;
    }
  }
`;
