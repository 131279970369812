import React from 'react';

import ApplyTherapySC from './apply-therapy.styled';
import JpgPhone from '../../../../assets/img/jpg/phone.jpg';

const ApplyTherapy = () => {
  return (
    <ApplyTherapySC>
      <div className="left">
        <h3>How apply Physical Vascular Therapy?</h3>
        <p>Surely You have other questions about how and why apply Physical Vascular Therapy.</p>
        <p>
          We can also arrange a zoom.us meeting where we can discuss your questions and questions in
          detail. We will also regularly do webinars, specific problems (back pain, migraine,
          stress, etc.).
        </p>
        <p className="ready">We are ready to answer your questions:</p>
        <span className="label">Send us an e-mail</span>
        <span className="link">
          <a href="mailto:ladislav.pasztor@hilbi.com">
            ladislav.pasztor@hilbi.com
          </a>
        </span>
        <span className="label">We will be available for your further questions</span>
        <span className="link">
          <a href="tel:+447938067150">+ 44 7938 067150</a>
        </span>
        <span className="label">WhatsApp number</span>
        <span className="link">
          <a href="tel:+447723715472">+ 44 7723715472</a>
        </span>
      </div>
      <div className="right">
        <img src={JpgPhone} alt="phone" />
      </div>
    </ApplyTherapySC>
  );
};

export default ApplyTherapy;
