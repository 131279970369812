import styled from 'styled-components';
import { BP_MEDIUM, COLOR_PRIMARY_ORANGE_10 } from '../../../../constants';

export default styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;

  .left {
    max-width: 630px;
    padding-right: 75px;
    width: 100%;
    height: auto;

    text-align: left;

    .ready {
      margin-top: 50px;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
    }

    span {
      display: block;

      &.label {
        margin-top: 20px;
      }

      &.link {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.5;
        color: ${COLOR_PRIMARY_ORANGE_10};

        a {
          font-size: 20px;
          font-weight: 700;
          line-height: 1.5;
          color: ${COLOR_PRIMARY_ORANGE_10};
        }
      }
    }
  }

  img {
    max-width: 506px;
    width: 100%;
    height: auto;
  }

  @media (max-width: ${BP_MEDIUM}) {
    flex-direction: column-reverse;

    .left {
      margin-top: 30px;
      padding-right: 0;

      span {
        &.link {
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
