import styled from 'styled-components';
import SvgDot from '../../../../assets/img/svg/dott.svg';
import { BP_MEDIUM, COLOR_PRIMARY_BLACK_10 } from '../../../../constants';

export default styled.div`
  margin-top: 100px;

  .content {
    display: flex;
    justify-content: space-between;

    &__left {
      max-width: 630px;
      padding-right: 75px;
      width: 100%;
      height: auto;
      text-align: left;
    }

    &__right {
      width: 100%;

      ul {
        font-size: 20px;
        line-height: 1.5;
        color: ${COLOR_PRIMARY_BLACK_10};
        list-style-image: url(${SvgDot});
      }
    }
  }

  @media (max-width: ${BP_MEDIUM}) {
    margin-top: 50px;
    .content {
      flex-direction: column;
      
      &__left {
          padding: 0;
      }
    }
  }
`;
