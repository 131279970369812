import React, { useContext } from 'react';

import HomePageSC from './home-page.styled';

import ContentWrapper from '../../components/content-wrapper';
import DiabetesComplications from './components/diabetes-complications';
import DiabeticFoot from './components/diabetic-foot';
import HealthProblems from './components/health-problems';
import PasztorBlock from '../../components/pasztor-block';
import BigQuote from '../../components/big-quote';
import Testimonials from '../../components/testimonials';
import ContactWebinar from '../../components/contact-webinar';

const HomePage = () => {
  return (
    <HomePageSC>
      <ContentWrapper>
        <DiabetesComplications />
        <DiabeticFoot />
        <HealthProblems />
        <PasztorBlock />
        <BigQuote type="diabetes" />
        <Testimonials type="diabetes" />
        <ContactWebinar />
      </ContentWrapper>
    </HomePageSC>
  );
};

export default HomePage;
