import React from 'react';
import { Link } from 'react-router-dom';
import FooterSC from './footer.styled';
import SvgBiomicrorealLogoDark from '../../assets/img/svg/biomicroreal-logo-dark.svg';
import ContentWrapper from '../content-wrapper';
import { ROUTE_HOMEPAGE } from '../../constants';

const Footer = () => {
  return (
    <ContentWrapper>
      <FooterSC>
        <div className="row">
          <div className="row__left">
            <Link to={ROUTE_HOMEPAGE}>
              <img src={SvgBiomicrorealLogoDark} />
            </Link>
          </div>
          <div className="row__right">
            <span>
              <strong>Biomicroreal.com</strong> All rights reserved
            </span>
          </div>
        </div>
      </FooterSC>
    </ContentWrapper>
  );
};

export default Footer;
