import React from 'react';

import styled from 'styled-components';

import SvgConsultation from '../../assets/img/svg/consultation.svg';
import SvgWebinar from '../../assets/img/svg/webinar.svg';
import {
  BP_MEDIUM,
  COLOR_BACKGROUND_ORANGE_10,
  COLOR_PRIMARY_ORANGE_10,
  COLOR_PRIMARY_WHITE_10,
} from '../../constants';

const ContactWebinarSC = styled.div`
  display: flex;
  margin-top: 100px;
  margin-bottom: 100px;

  .left,
  .right {
    max-width: 569px;
    width: 100%;
    background-color: ${COLOR_BACKGROUND_ORANGE_10};

    height: 330px;
    display: flex;
    justify-content: center;

    h3 {
      margin-top: 30px;
    }

    a {
      font-size: 14px;
      font-weight: bold;

      line-height: 1.43;
      text-align: center;
      color: ${COLOR_PRIMARY_WHITE_10} !important;
      width: 182px;
      height: 60px;
      border-radius: 30px;
      background-color: ${COLOR_PRIMARY_ORANGE_10};
      border-width: 0;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }

    &__content {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .left {
    margin-right: 1px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .right {
    margin-left: 1px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @media (max-width: ${BP_MEDIUM}) {
    flex-direction: column;
    margin-top: 50px;
    
    .right {
      margin-top: 2px;
    }
  }
`;

const ContactWebinar = () => {
  return (
    <ContactWebinarSC>
      <div className="left">
        <div className="left__content">
          <img src={SvgConsultation} alt="contact-symbol" />
          <h3>Contact Us</h3>
          <a href="mailto:ladislav.pasztor@hilbi.com">Send</a>
        </div>
      </div>
      <div className="right">
        <div className="right__content">
          <img src={SvgWebinar} alt="contact-symbol" />
          <h3>Free Webinar</h3>
          <a href="mailto:ladislav.pasztor@hilbi.com">Sign Up</a>
        </div>
      </div>
    </ContactWebinarSC>
  );
};

export default ContactWebinar;
