import React from 'react';

import styled from 'styled-components';
import { COLOR_PRIMARY_GREY_10, COLOR_PRIMARY_ORANGE_10 } from '../../constants';

const NotFoundSC = styled.div`
  text-align: center;
  height: calc(100vh - 530px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  span {
    &.four-o-four {
      font-size: 300px;
      font-weight: 700;
      line-height: 1;
      color: ${COLOR_PRIMARY_ORANGE_10};
    }
    &.try-again {
      font-size: 24px;
      line-height: 1.46;
      color: ${COLOR_PRIMARY_GREY_10};
    }
  }
`;

const NotFound = () => {
  return (
    <NotFoundSC>
      <span className="four-o-four">404</span>
      <h2>We coultn’t find this page</h2>
      <span className="try-again">please try again later.</span>
    </NotFoundSC>
  );
};

export default NotFound;
