import styled from 'styled-components';
import { BP_MEDIUM } from '../../../../constants';

export default styled.div`
  margin-top: 100px;

  .content {
    display: flex;
    justify-content: space-between;

    &__left {
      max-width: 630px;
      padding-right: 75px;
      width: 100%;
      height: auto;
      text-align: left;
    }

    &__right {
      width: 100%;
    }
  }

  @media (max-width: ${BP_MEDIUM}) {
    margin-top: 50px;
    .content {
      flex-direction: column;
    }
  }
`;
