import React from 'react';

import DiabetesComplicationsSC from './diabetes-complications.styled';
import JpgDiabetesSchema from '../../../../assets/img/jpg/diabetes-schema.jpg';

const DiabetesComplications = () => {
  return (
    <DiabetesComplicationsSC>
      <div className="left">
        <p>
          <br />
          The biggest problem for all people diabetes is they follow all the recommendations for a
          healthy lifestyle for the diabetic (diet, physical exercise, weight management,
          non-smokers, etc.), and they use medication or insulin as recommended. But in 10-12% of
          patients chronic complications of diabetes coming
        </p>
        <ul>
          <li>Cerebral vascular disease (stroke)</li>
          <li>Eye damage (up to blindness)</li>
          <li>Coronary heart disease (heart attack)</li>
          <li>Kidney damage (kidney failure)</li>
          <li>Peripheral nerve damage (polyneuropathy)</li>
          <li>Diabetic foot (finger and limb amputation)</li>
          <li>Peripheral vascular disease (leg ulcer, limb amputation)</li>
        </ul>
        <div className="quote">
          <span className="quote__text">
            10-12% of patients chronic complications of diabetes coming
          </span>
        </div>
      </div>
      <div className="right">
        <img src={JpgDiabetesSchema} alt="diabetes-schema" />
      </div>
    </DiabetesComplicationsSC>
  );
};

export default DiabetesComplications;
