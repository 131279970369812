import styled from 'styled-components';
import { BP_MEDIUM, COLOR_PRIMARY_BLACK_10, COLOR_PRIMARY_ORANGE_10 } from '../../../../constants';
import SvgDot from '../../../../assets/img/svg/dott.svg';
import SvgQuotes from '../../../../assets/img/svg/quotes.svg';
export default styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  .left {
    max-width: 630px;
    padding-right: 75px;
    width: 100%;
    height: auto;

    text-align: left;
  }

  img {
    max-width: 506px;
    width: 100%;
    height: auto;
  }

  ul {
    font-size: 20px;
    line-height: 1.5;
    color: ${COLOR_PRIMARY_BLACK_10};
    list-style-image: url(${SvgDot});
    padding-left: 18px;
  }

  .quote {
    background-image: url(${SvgQuotes});
    background-position: top left;
    background-repeat: no-repeat;
    height: 240px;
    margin-top: 80px;

    &__text {
      display: block;
      padding-top: 30px;
      font-family: 'PT Serif', serif;
      font-size: 50px;
      font-style: italic;
      line-height: 1.4;
      text-align: center;
      color: ${COLOR_PRIMARY_ORANGE_10};
    }
  }

  @media (max-width: ${BP_MEDIUM}) {
    flex-direction: column-reverse;
    .left,
    .right {
      max-width: 100%;
      padding: 0;
    }

    .quote {
      &__text {
        font-size: 29px;
        font-style: italic;
        line-height: 1.72;
        text-align: center;
      }
    }
  }
`;
