import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';

import ContentWrapper from '../content-wrapper';

import TopMenuSC from './top-menu.styled';
import SvgBiomicrorealLogo from '../../assets/img/svg/biomicroreal-logo.svg';
import SvgFacebookLogo from '../../assets/img/svg/facebook-square.svg';
import SvgMenuLogo from '../../assets/img/svg/mobile-menu.svg';

import { ROUTE_ABOUT, ROUTE_HOMEPAGE, ROUTE_MIGRAINE } from '../../constants';
import { StateContext } from '../../context';

const TopMenu = (props) => {
  const {
    history: { push },
    location: { pathname },
  } = props;

  const { responsiveView, setResponsiveMenuOpen } = useContext(StateContext);

  if (responsiveView) {
    return (
      <TopMenuSC>
        <ContentWrapper>
          <div className="menu-row">
            <div className="logo" onClick={() => push('/')}>
              <img className="logo__image" src={SvgBiomicrorealLogo} alt="Biomicroreal-logo" />
            </div>

            <ul>
              <li>
                <a href="https://www.facebook.com/BiomicrorealLtd/" target="_blank">
                  <img src={SvgFacebookLogo} alt="facebook-logo" />
                </a>
              </li>
              <li>
                <button onClick={() => setResponsiveMenuOpen(true)} href="#">
                  <img src={SvgMenuLogo} alt="menu-icon" />
                </button>
              </li>
            </ul>
          </div>
        </ContentWrapper>
      </TopMenuSC>
    );
  }

  return (
    <TopMenuSC>
      <ContentWrapper>
        <div className="menu-row">
          <div className="logo" onClick={() => push('/')}>
            <img className="logo__image" src={SvgBiomicrorealLogo} alt="Biomicroreal-logo" />
          </div>

          <ul>
            <li className={pathname === ROUTE_HOMEPAGE ? 'is-active' : null}>
              <Link to={ROUTE_HOMEPAGE}>Diabetes</Link>
            </li>
            <li className={pathname === ROUTE_MIGRAINE ? 'is-active' : null}>
              <Link to={ROUTE_MIGRAINE}>Migraine</Link>
            </li>
            <li className={pathname === ROUTE_ABOUT ? 'is-active' : null}>
              <Link to={ROUTE_ABOUT}>About Us</Link>
            </li>
            <li>
              <a href="https://www.facebook.com/BiomicrorealLtd/" target="_blank">
                <img src={SvgFacebookLogo} alt="facebook-logo" />
              </a>
            </li>
          </ul>
        </div>
      </ContentWrapper>
    </TopMenuSC>
  );
};

export default withRouter(TopMenu);
