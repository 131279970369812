import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BP_MEDIUM } from '../../constants';

const ContentWrapperSC = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${BP_MEDIUM}) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const ContentWrapper = (props) => {
  const { children } = props;
  return <ContentWrapperSC>{children}</ContentWrapperSC>;
};

ContentWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ContentWrapper;
