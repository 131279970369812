import styled from 'styled-components';
import { COLOR_PRIMARY_WHITE_10, COLOR_PRIMARY_ORANGE_10 } from '../../constants';

export default styled.div`
  height: 70px;

  display: flex;
  flex-direction: column;
  justify-content: end;

  .menu-row {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;

    .logo {
      width: 135px;

      height: 34px;
      margin-top: 38px;

      cursor: pointer;

      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        flex-direction: column;
        padding-top: 42px;
        margin-right: 25px;
        height: 70px;

        &:last-of-type {
          margin-right: 0;
        }

        &.is-active {
          border-top: 5px solid ${COLOR_PRIMARY_ORANGE_10};
        }

        a {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.43;
          text-align: right;
          color: ${COLOR_PRIMARY_WHITE_10};

          img {
            margin-bottom: 2px;
          }
        }
        button {
          background-color: transparent;
          border: 0;
        }
      }
    }
  }
`;
