import React from 'react';

import HealthProblemsSC from './health-problems.styled';

const HealthProblems = () => {
  return (
    <HealthProblemsSC>
      <h3>Do you feel any of the accompanying health problems of a diabetic?</h3>
      <div className="content">
        <div className="content__left">
          <p>
            The common denominator of all these complications is the damage of peripheral nerves and
            microcirculation.{' '}
          </p>
          <p>
            And also the fact that all of these complications are difficult to prevent and very
            difficult to cure.
          </p>
        </div>
        <div className="content__right">
          <ul>
            <li>Are you getting worse? </li>
            <li>Do you have cold feet? </li>
            <li>Armour in them?  </li>
            <li>Or sharp pains for which you can't even sleep?</li>
            <li>Do you have wounds on your leg that do not heal?</li>
            <li>Or heals only very slowly?</li>
            <li>Does no one seem to be able to help you?</li>
          </ul>
        </div>
      </div>
    </HealthProblemsSC>
  );
};

export default HealthProblems;
